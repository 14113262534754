// New Colors
$ubbe_blue: #25215F;
$ubbe_yellow: #FFCF01;
$ubbe_yellow_75: #FFDB40;
$ubbe_yellow_55: #FFE573;
$ubbe_yellow_light: #FFFEF8;
$ubbe_dark_black: #333333;
$ubbe_order_sand: #FFE388;
$ubbe_extra_light: #FFF3D0;

// Old Colors
$gobox_dark_blue: #141B4D;
$gobox_light_blue: #385E9D ;
$gobox_grey: #5A5A5A;
$mobil_red: #FE000C;

.dark_blue{
  color: $ubbe_blue;
}

.grey{
  color: $gobox_grey;
}

.red{
  color: $mobil_red;
}

.ubbe-blue {
  color: $ubbe_blue
}

.ubbe-yellow {
  color: $ubbe_yellow
}

.ubbe-yellow-light {
  color: $ubbe_yellow_light
}

.ubbe-yellow-bg {
  background-color: $ubbe_yellow
}

.ubbe-blue-bg {
  background-color: $ubbe_blue
}

.ubbe-red-bg {
  background-color: $mobil_red
}