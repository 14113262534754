@font-face {
  font-family: Lexend;
  src: url(./Lexend-VariableFont_wght.ttf);
}
.page-spacing {
  margin: 0 4rem 0 4rem !important;
}
.float_left {
  float: left;
}
.text-wrap-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.report-spinner-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(87, 85, 85, 0.5);
  z-index: 1000;
}
.tooltip-styles {
  white-space: pre-line;
  text-align: left;
  padding: 10px;
  font-size: 10px;
  font-weight: 400;
  color: #cccccc00;
}
.justify-end {
  display: flex;
  justify-content: flex-end;
}

.autosuggest-container {
  position: relative;
}

.suggestions-container {
  z-index: 9999;
  position: absolute;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  border: none;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.suggestions-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.suggestion {
  padding: 5px 10px;
  cursor: pointer;
  font-size: 10px;
  font-weight: 400;
}

.country-border {
  border-top: 1px solid #ddd;
  padding: 10px 20px;
}

.suggestion:hover {
  background-color: #ddd;
}

.sort-icon {
  font-size: 10px;
  margin-left: 5px;
}

.change-country {
  font-size: 10px;
  font-weight: 500;
  color: #969696;
  padding: 0;
  position: relative;
  float: right;
  margin-right: 15px;
}
@media (min-width: 1400px) {
  .page-spacing {
    margin: 0 15rem 0 15rem !important;
  }
  .landing {
    background: url("../icons/Banner1.png"), url("../icons/Polygon.png"),
      url("../icons/Polygon.png"), url("../icons/Vector (2).png"),
      url("../icons/Vector (1).png");
    background-position: top left, center 32vh, 52vw -40vh, 50% 166vh, 23% 235vh;
    background-repeat: no-repeat;
    background-size: 100% 84vh, 100% 130vh, 50% 80vh, 35% auto, 17% auto;
    overflow-x: hidden;
  }
  .normal_bg {
    background: url("../icons/Vector_large.png"), url("../icons/Vector.png"),
      url("../icons/Vector.svg"), url("../icons/Vector_X_Large.png"),
      url("../icons/Vector_large.png"), url("../icons/Vector.png");
    background-position: 50% 9vh, 26% 68vh, 75% 133vh, -2% 170vh, 50% 333vh,
      23% 388vh;
    background-repeat: no-repeat, no-repeat;
    background-size: 35% auto, 20% auto, 31% auto, 63% auto, 35% auto, 17% auto;
  }
  .ubbe-card-login-size {
    margin: 10%;
  }
}

.ubbe-card-header {
  border-radius: 8px 8px 0px 0px;
  background: #251163;
  color: white;
  height: 53px;
  display: flex;
  align-items: center;
  color: var(--White, var(--white, #fff));
  font-family: Lexend;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.ubbe-card-header-small {
  font-size: 18px !important;
  font-weight: 300 !important;
}

.ubbe-card-header-white {
  border-radius: 8px 8px 0px 0px;
  background: white;
  color: black !important;
  height: 53px;
  display: flex;
  align-items: center;
  font-family: Lexend;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}
.whitetable-global {
  border-radius: 8px, 8px, 0px, 0px !important;
  border: 1px solid #80808080;
}
.dropdown-label {
  color: var(--Grey-Black, #000);
  font-family: Lexend;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 4px;
}

// New nav
.navbardropdown .dropdown-menu {
  left: unset !important;
  right: 2px;
}
.navbar-light .navbar-nav .nav-link {
  color: var(--Light-Black, black) !important;
}
.sideAvatarName {
  color: var(--Light-Black, black) !important;

  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0px;
}
.handle .dropdown-toggle::after {
  margin-left: 11px;
  display: inline-block;
  vertical-align: middle;
  content: url("../icons//new_icon/vector-dropdown.svg") !important;
  border: none;
}

.searchIcon {
  margin-left: -50px !important;
  z-index: 999;
  background-color: transparent !important;
  border: none !important;
}

.searchIcon-lms {
  width: 18px;
  height: 18px;
  background-color: transparent !important;
  z-index: 999;
  border: none !important;
}

.navMainhead {
  color: #292d32;
  font-family: Lexend;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 33.6px */
  margin-left: 85px;
  cursor: pointer;
}
.navdroptext {
  color: #000;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px; /* 271.429% */
}

// Quote Stepper

.steppertext {
  color: #292d32 !important;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: uppercase;
}
.stepperBg {
  border-radius: 23px;
  background: var(--Supporting-Color-1, #afd100) !important;
}
.round {
  position: relative;
  width: max-content;
  display: flex;
  gap: 10px;
  align-items: center;
  color: var(--Grey-Black, #000);

  margin-top: 8px;
}

.round label {
  background-color: #c1c9d2;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 16px;
  left: 0;
  position: absolute;
  top: 2px;
  width: 16px;
  margin-top: 4px;
  margin-top: 4px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 4px;
  left: 4px;
  opacity: 0;
  position: absolute;
  top: 4px;
  transform: rotate(-45deg);
  width: 6px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: #afd100;
  border-color: #afd100;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}
.activecheckedhead {
  display: flex !important;
  justify-content: flex-start;
  gap: 6px;
}
.activechecked.form-check-input:checked[type="radio"] {
  background-color: #afd100;
  border-color: #afd100;
  margin-top: 5.6px;
}
.boldsteppertext {
  color: var(--Grey-Black, #000);

  /* Stepper Selected */
  font-family: Lexend;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}
.boldglobaltext {
  color: var(--Grey-Black, #000);

  /* Stepper Selected */
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.normalsteppertext {
  color: #292d32;

  /* 18 | Light */
  font-family: Lexend;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
}
// Global LabelInput
.boldstepperhead {
  color: #292d32;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}
.labelText {
  color: #000;

  /* 18 | Regular */
  font-family: Lexend;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.labelInput {
  border-radius: 6px;
  border: 1px solid var(--gray-200, #e2e8f0);
  background: var(--White, #fff);
  height: 48px;
}
.labelInput::placeholder {
  color: rgba(0, 0, 0, 0.5);
  font-family: Lexend;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 100%; /* 18px */
}

// Ground Career Option

.ubbe-new-card {
  border-radius: 8px;
  background: var(--white, #fff);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
}
// Package Box

.packageBoxIcon {
  padding: 5px;
  border-radius: 100%;
}
// GlobalBoxHead
.GlobalBoxHead {
  color: var(--White, var(--white, #fff));
  font-family: Lexend;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 28px */
  margin-left: 8px;
}
// GlobalInputlabel

.globalInputLabel {
  color: #000;

  /* 18 | Regular */
  font-family: Lexend;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.disable_globalInputLabel {
  color: #747474 !important;
}
.disable_discription {
  //styleName: Description;
  font-family: Lexend;
  font-size: 18px;
  font-weight: 300;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
  color: #3e3e3e;
}

.divider {
  color: var(--Grey-Black, #000);

  /* 18 | Regular */
  font-family: Lexend;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
}

.divider::before,
.divider::after {
  flex: 1;
  content: "";
  padding: 0.1px;
  background-color: #c1c9d2;
  margin: 15px;
}
.mandatory-text {
  color: var(--Error, #fc0000);
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 16px */
}

.search-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.search-icon-lms {
  position: absolute;
  right: 10px;
  top: 40%;
  transform: translateY(-50%);
}
.clear-icon {
  position: relative;
  right: 40px;
  top: 70%;
  transform: translateY(-50%);
}
.track-clear-icon {
  position: relative;
  right: 20px;
  top: 70%;
  transform: translateY(-50%);
}
.eye-icon {
  position: absolute;
  right: 1%;
  top: 20%;
}

.rates__title {
  color: var(--Grey-Black, #000);
  font-family: Lexend;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 100% */
  letter-spacing: 0.1px;
}

.ubbe-table-header {
  background: var(--table-colmn, #f4f2ff);
  padding-top: 16px;
  padding-bottom: 16px;
}
.table-header {
  background-color: #f4f2ff;
}
.ubbe-table-row {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 300;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.ubbe-table-heading-text {
  color: var(--Grey-Black, #000);
  font-family: Lexend;
  font-size: 18px;
  font-style: normal;

  font-weight: 400;
  line-height: 100%; /* 18px */
  padding: 10px 16px !important ;
}

.mandatory-label {
  color: var(--Error, #fc0000);
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 16px */
  align-self: flex-end;
}

.save-quote-dropdown {
  width: 500px;
  border-radius: 6px;
  border: 1px solid var(--gray-200, #e2e8f0);
  background: var(--White, #fff);
}

.terms-heading {
  color: var(--Grey-Black, #000);
  font-family: Lexend;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 100% */
  letter-spacing: 0.1px;
}

.terms-dropdown {
  width: 300px;
}

.lex-black-18-300 {
  color: var(--Grey-Black, #000);
  font-family: Lexend;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 100%; /* 18px */
}
.lex-main-18-400 {
  color: var(--Main-color, #251163);

  font-family: Lexend;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 18px */
}

.lex-18-400 {
  font-family: Lexend;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 18px */
}

.lex-20-500 {
  font-family: Lexend !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 20px !important; /* 100% */
  letter-spacing: 0.1px !important;
}

.lex-20-300 {
  font-family: Lexend;
  font-size: 20px;
  font-weight: 300;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: center;
}

.lex-20-400 {
  font-family: Lexend;
  font-size: 20px;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: center;
}

.lex-black-16-400 {
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 18px */
}

.lex-24-500 {
  font-family: Lexend;
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
}
.lex-16-400 {
  font-family: Lexend;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}
.lex-16-500 {
  font-family: Lexend;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}
.lex-16-300 {
  font-family: Lexend;
  font-size: 16px !important;
  font-weight: 300 !important;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

.lex-25-600 {
  font-family: Lexend;
  font-size: 25px;
  font-weight: 600;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: left;
}

.lex-14-300 {
  font-family: Lexend;
  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

.lex-14-400 {
  font-family: Lexend;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
}

.lex-48-700 {
  font-family: Lexend;
  font-size: 48px;
  font-weight: 700;
  line-height: 67px;
  letter-spacing: 0em;
  text-align: left;
}

.lex-12-300 {
  font-family: Lexend;
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
}

.color-white {
  color: white;
}

.color-black {
  color: black;
}

.color-danger {
  color: #fc0000;
}

.color-primary {
  color: #251163;
}
.color-main {
  color: #251163;
}
.bg-main {
  background-color: #251163 !important;
}

.bg-danger-red {
  background-color: #fc0000 !important ;
}

.color-muted {
  color: #979797;
}
.ubbe-dark-border {
  border-radius: 6px !important;
  border: 1px solid var(--Grey-Black, #000) !important;
}

.btn-ubbe-green {
  color: #251163 !important;
  background-color: #afd100 !important;
  border-color: #afd100 !important;
  height: 48px !important;
}

.btn-bc-blue {
  color: white !important;
  background-color: red !important;
  border-color: red;
  color: var(--White, var(--white, #fff));

  /* table heading */
  font-family: Lexend;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 18px */
}

// summary new design
.editwhiteBg {
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 50%;
}
.form-control.is-invalid {
  background-image: unset !important;
}
.ubbe-edit-card {
  box-shadow: 0px 1px 3px 0px #00000040;
  background-color: #e5e5e5;
  border-radius: 7px;
}

.highlighted-border {
  border: 1px solid black !important;
}

.css-1jqq78o-placeholder {
  font-weight: 300 !important;
}
.react-datepicker__view-calendar-icon input {
  padding: 12px !important;
}

//Confimration screen //

.shippingheading {
  //styleName: Big Heading;
  font-family: Lexend;
  font-size: 32px;
  font-weight: 600;
  line-height: 45px;
  letter-spacing: 0em;
  text-align: center;
  color: #251163;
  margin-top: -35px;
}

.shippingparagraph {
  font-family: Lexend;
  font-size: 18px;
  font-weight: 300;
  // line-height: 45px;
  letter-spacing: 0em;
  text-align: center;
  color: black;
  margin-top: 8px;
}

.form-control:focus {
  border: 1px solid black !important;
  box-shadow: none !important;
}

// textarea:focus,
// textarea.form-control:focus,
// input.form-control:focus,
// input[type=text]:focus,
// input[type=password]:focus,
// input[type=email]:focus,
// input[type=number]:focus,
// [type=text].form-control:focus,
// [type=password].form-control:focus,
// [type=email].form-control:focus,
// [type=tel].form-control:focus,
// [contenteditable].form-control:focus {
//   box-shadow: none;
//   border: 1px solid black !important
// }

select:focus {
  outline: none !important;
  box-shadow: none !important;
}
.promo-code-btn {
  margin: 0 !important;
}
.newubbemodalheading {
  font-family: Lexend;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}
.modal-header {
  border-bottom: 1px solid #80808080 !important;
}
.activechecked.form-check-input:checked[type="radio"] {
  background-image: url("../icons/new_icon/check-circle.svg");
  background-color: unset !important;
  border-color: unset;
  border: unset;
  width: 16px;
}

// Confirm shipment //
.lootiemain-container {
  position: relative;
  width: 300px;
  height: 178px;
  justify-content: center;
  margin: auto;
  display: flex;
}
.lootiemain-container svg {
  position: absolute;
  width: 242px !important;
  top: 0px;
}

.text-danger {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 300;
  line-height: 32px;
  letter-spacing: 0px;
  color: #fc0000;
}

.modal-title {
  font-family: Lexend;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}
textarea {
  resize: none !important;
}

.ubbe-accordian-header {
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 55px;
}

.accordian-card-header-main {
  background-color: #251163 !important;
}

.saved-quote-heading {
  padding: 16px 32px 16px 32px;
  border: 2px solid #251163;
  border-radius: 6px;
}
.status::after {
  display: none !important;
}

// Global chips //
.badge {
  font-weight: 400 !important;
}
.delivered-chip {
  padding: 8px;
  border-radius: 36px;
  gap: 8px;
  background: #31bc0e;
  //styleName: table heading;
  font-family: Lexend;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  color: white;
}
.inprogress-chip {
  padding: 8px !important;
  border-radius: 36px;
  gap: 8px;
  background: #1a61cc;
  //styleName: table heading;
  font-family: Lexend;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  color: #ffffff !important;
}
.cancelled-chip {
  padding: 8px !important;
  border-radius: 36px;
  gap: 8px;
  background: #fc0000;
  //styleName: table heading;
  font-family: Lexend;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  color: #ffffff !important;
}
.created-chip {
  padding: 8px !important;
  border-radius: 36px;
  gap: 8px;
  background: #2f3437;
  //styleName: table heading;
  font-family: Lexend;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  color: white !important;
}
.pickup-chip {
  padding: 8px !important;
  border-radius: 36px;
  gap: 8px;
  background: #b37cc4;
  //styleName: table heading;
  font-family: Lexend;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  color: #ffffff !important;
}
.intransit-chip {
  padding: 8px !important;
  border-radius: 36px;
  gap: 8px;
  background: #00b2b1;
  //styleName: table heading;
  font-family: Lexend;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  color: #ffffff !important;
}
.legs tr {
  background-color: #ffffff !important;
  height: 50px !important;
}
.specialIns {
  font-family: Lexend;
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
  color: #979797;
}
tr td {
  padding: 10px 16px !important;
}
.dropdown-menu {
  z-index: 9999 !important; /* or any high enough value */
}
//filterbtns //
.disable-filter {
  background: #e5e5e5;
  height: 48px;
  padding: 16px, 32px, 16px, 32px !important;
  border-radius: 6px;
  gap: 8px;
  //styleName: table heading;
  font-family: Lexend;
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: #747474;
  border: none;
}
.active-filter {
  background: #251163;
  height: 48px;
  padding: 16px, 32px, 16px, 32px !important;
  border-radius: 6px;
  gap: 8px;
  //styleName: table heading;
  font-family: Lexend;
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: white;
  border: none;
}
.breadcrumb-item + .breadcrumb-item::before {
  content: ">" !important;
}
.breadcrumb-item {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0em;
  text-align: left;
}
.breadcrumb-item.active {
  font-family: Lexend;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #251163 !important;
}
.createdby {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 300;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}
.ubbe-new-btn {
  background-color: white !important;
  padding: 0px !important;
  width: 28px !important;
  border-radius: 8px !important;
}
.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: white !important;
  --bs-table-accent-bg: white !important;
}
.accordion-flush .accordion-item .accordion-button {
  border-top-right-radius: 8px !important;
  border-top-left-radius: 8px !important;
}
.accordion-border {
  border-radius: 8px !important;
  border-top-right-radius: 8px !important;
  border-top-left-radius: 8px !important;
}
.table > :not(caption) > * > * {
  border-bottom-width: 0px !important ;
}
.accordion-item {
  border-radius: 8px !important;
}
tr {
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  border-bottom: 1px solid black !important;
}
tr::first-line {
  border-top: none !important;
}
tr:last-child {
  border-bottom: none !important;
}
.ubbe-blue-new {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #251163;
}
.tooltip-text {
  //styleName: Tool tip;
  font-family: Lexend;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}
.customperpage {
  width: 70px !important;
  padding: 8px, 15px, 8px, 15px !important;
  border-radius: 8px !important;
  border: 1px !important;
  gap: 8px;
  border: 1px solid #dddddd !important;
  background: linear-gradient(0deg, #ffffff, #ffffff) !important;
}
tr {
  height: 50px;
}

//new pagination styling //
.leftcontrol {
  background-color: unset !important;
  border: none !important;
  cursor: pointer;
  padding: 0 !important;
}
.page-item.active .page-link {
  background-color: #251163 !important;
  color: white !important;
  border: none !important;
  font-family: Lexend !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  letter-spacing: 0em;
  text-align: left;
  height: 32px;
  border-radius: 8px !important;
}
.page-link {
  font-family: Lexend;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #3e3e3e !important;
  border: 1px solid #000000 !important;
  height: 32px;
  border-radius: 8px !important;
  border: 1px;
}
.page-item:not(:first-child) .page-link {
  font-family: Lexend !important;
  border-radius: 8px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  letter-spacing: 0em;
  text-align: left;
}
.accordion-button:focus {
  z-index: unset !important;
}
.underline-blue {
  font-family: Lexend;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff !important;
  text-decoration: underline !important;
  background-color: unset !important;
  border: none !important;
}
.custom-select {
  height: 48px;
  padding: 10px, 14px, 10px, 14px;
  border-radius: 6px !important;
  border: 1px !important;
  // justify: space-between;
  background-color: white !important;
}
.custom-select-notselected {
  border: 1px solid #e6e6e6 !important;
}
.custom-select-selected {
  border: 1px solid black !important;
}
.w-149 {
  width: 149px !important;
}
.ubbe-choose-input {
  padding: 30px;
  border: 1px dotted;
  border: 1px dotted #251163;
}
.ubbe-choose-input button {
  background-color: white !important;
}
.upload-file-container {
  text-align: center;
  padding: 30px;
  border: 2px dotted #251163;
  position: relative;
  border-radius: 6px;
}
.error-border {
  border: 2px dotted red;
}
.custom-file-upload {
  cursor: pointer;
  background: white !important;
  border: none !important;
  font-family: Lexend;
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #251163;
  text-decoration: underline;
}
.file-info {
  margin-top: 10px;
  background-color: #f3f3f3;
  padding: 10px;
  min-height: 90px;
  border-radius: 4px !important;
  height: max-content;
}
.progress-container {
  width: 100%;
  height: 6px;
  background-color: #f0f0f0;
  border-radius: 5px;
  margin-top: 5px;
}
.progress-bar {
  height: 100%;
  background-color: #251163 !important;
  border-radius: 5px;
  margin-top: 15px !important;
}
.progress-text {
  font-family: Lexend;
  font-size: 10px;
  font-weight: 300;
  line-height: 13px;
  letter-spacing: 0em;
  color: #000000;
}
.delete-button {
  background: none;
  border: none;
  color: red;
  cursor: pointer;
}
.btn-Information {
  border: 1px solid #251163 !important;
  color: #251163;
  min-width: 283px;
  height: 48px;
  padding: 16px;
  border-radius: 6px;
  border: 1px;
  display: flex !important;
  align-items: center;
  //styleName: 18 | Regular;
  font-family: Lexend;
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  background-color: white !important;
}

.dashboard-border-bottom-card {
  box-shadow: 0px 1px 3px 0px #00000040;
  border-radius: 8px;
  border-bottom: 8px solid #00b2b1;
}
.metric-border-bottom-card {
  box-shadow: 0px 1px 3px 0px #00000040;
  border-radius: 8px;
  border-bottom: 8px solid #b37cc4;
}
.reports-border-left-card {
  // box-shadow: 0px 1px 3px 0px #00000040;
  border-radius: 8px;
  border-left: 8px solid #251163;
}
.ms-container:after {
  display: none !important;
}
.ms-container {
  width: 77% !important;
  margin-top: -10px;
}
.itemMuliple {
  // height: 33px !important;
  padding: 5px 10px !important;
  display: flex !important;
  // align-items: center !important;
  gap: 8px !important;
  cursor: pointer !important;
}
.itemMuliple:hover {
  background-color: #e5e5e5;
}
.ms-list::-webkit-scrollbar {
  // width: 4px !important;
}
.borderblack {
  border: 1px solid #000000;
  border-radius: 6px;
}
.ms-container .ms-list {
  border: none !important;
  box-shadow: none !important;
}
.Btop {
  border-top: 1px solid #000000;
}

.bodycard {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.table > thead {
  border-bottom: 1px solid black;
}
.overdue-mark-delivered {
  padding: 8px;
  border-radius: 43px;
  border: none;
  font-family: Lexend;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;
  background: #31bc0e;
  color: white;
}
.overdue-mark-delivered:hover {
  opacity: 0.7;
}

.stepper-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.dot {
  width: 20px;
  height: 20px;
  background-color: #808080;
  border-radius: 50%;
}

.step-text {
  font-size: 14px;
  color: #333;
}

.line {
  width: calc(100% - 20px); /* Adjust the width of the line */
  height: 2px; /* Adjust the thickness of the line */
  border-bottom: 2px dashed #808080; /* Adjust line color and style */
  position: absolute;
  top: 17%;
  margin-left: 20px;
}
.stepper-step {
  width: 100%;
  position: relative;
}
.stepper-step-last {
  width: 10%;
}

.line.track-active {
  background-color: #251163;
  border-bottom: 2px dashed #251163;
}

.dot.track-active {
  background-color: #251163;
}

//verticle
.stepper-vertical-container {
  display: flex;
  flex-direction: column;
}

.stepper-vertical-step-1 {
  display: flex;
  align-items: start;
  position: relative;
  height: 110px;
}
.stepper-vertical-step-2 {
  display: flex;
  align-items: start;
  position: relative;
  height: 50px;
}

.line-solid {
  width: 2px;
  height: 30px; /* Adjust the height of the solid line */
  background-color: black;
  position: absolute;
  top: 20px; /* Adjust the position of the line relative to the dot */
  left: 10px; /* Adjust the position of the line relative to the dot */
}
.line-solid::after {
  content: "";
  position: absolute;
  background-color: transparent;
  width: 3px;
  height: 65px; /* Adjust the height of the dashed line */
  top: 30px; /* Adjust the position of the line relative to the dot */
  // left: 10px; /* Adjust the position of the line relative to the dot */
  border-left: 3px dashed #808080; /* Adjust the style and color of the dashed line */
}
.line-solid-icon {
  left: 9px;
}

.line-dashed-icon {
  left: 7px;
  height: 70px;
}

.date-range-picker {
  border: 1px solid black !important;
}
.dropdown-toggle-split {
  transform: translate3d(8px, -145.6px, 0px) !important;
}
.dropdown-toggle-split-overdue {
  transform: translate3d(90px, -120.6px, 0px) !important;
}
.bg-green {
  background-color: #251163 !important;
}
.bg-deactive {
  background-color: #3e3e3e !important ;
}

.back-to-site-button {
  position: fixed;
  right: 80px;
  bottom: 50px;
}

input.ubbe-input:disabled {
  background-color: #e0e0e4 !important; /* Change this to the desired background color */
  border: none !important;
  color: hsl(0, 0%, 60%);
}

.no-border-bottom {
  border-bottom: unset !important;
}

.pickup-disableInput {
  background: #e0e0e4;
  border: 1px solid #80808080;
  border-radius: 6px;
  height: 48px;
}

.react-datepicker__day--disabled {
  color: #ccc !important;
}

.bottom-10 {
  bottom: 10px;
}

.icon-right-input {
  padding-right: 45px !important;
}
.list-group {
  gap: 12px;
}
.dgIcon {
  margin-bottom: 2.35px;
  margin-right: 10px;
}

.w-100-imp {
  width: 100% !important;
}
.bi-three-dots-vertical {
  color: #000 !important;
}

.password-input {
  width: 580px;
}
.confirm-password-input {
}

.confirm-password-label {
  margin-top: 70px;
}

.leg-card-space {
  margin-top: 35px !important;
}
.text-center {
  text-align: center;
}
.small-light-text {
  color: #a7a7a7;
  font-size: 16px;
  font-weight: 500;
}
.spInsAddLeg {
  align-items: center;
  display: flex;
  height: calc(100% - 61px) !important;
  justify-content: center;
}
.pr-4-5 {
  padding-right: 30px !important;
}

.login-verify-btn {
  padding: 16px, 32px, 16px, 32px;
  height: 50px;
  width: 113px;
  background-color: #afd100;
  color: #000;
  border-radius: 46px;
  border: none;
  margin-top: 15px;
}
.resend_confirm_btn {
  background-color: #251163;
  color: #fff;
  width: 270px !important;
  height: 50px !important;
  float: left;
  border: none;
  border-radius: 6px;
  font-size: 18px;
  font-weight: 400;
}
.report_btn {
  font-family: Lexend;
  font-size: 18px !important;
  font-weight: 400 !important;
  line-height: 22.5px;
  text-align: center;
  background: #c80a0a;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  user-select: none;
  text-decoration: none;
  vertical-align: middle;
}
.lms_chip {
  background: #1a61cc1a;
  padding: 8px;
  border-radius: 4px 0px 0px 0px;
  color: #1a61cc;
  font-family: Lexend;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  height: 32px !important;
  justify-content: center;
  display: flex;
  width: max-content;
  text-transform: initial;
}
.lms_on_hand {
  background: #1a61cc1a;
  color: #1a61cc;
}
.lms_delivered {
  background: #31bc0e1a;
  color: #31bc0e;
}
.lms_loaded {
  background: #b37cc41a;
  color: #b37cc4;
}
.lms_in_transit {
  background: #2511631a;
  color: #251163;
}
.lmn_planned {
  background: #1a61cc1a;
  color: #1a61cc;
}
.code_99 {
  background: rgba(252, 0, 0, 0.1);
  color: #fc0000;
}
.unpicked {
  background: #6969691a;
  color: #696969;
}
.arrived {
  background: #9716571a;

  color: #971657;
}
.boarded {
  background: #32c68b1a;
  color: #32c68b;
}
.checkedIn {
  background: #cc7f1a1a;
  color: #cc7f1a;
}
.Transferred {
  background: #00b2b11a;

  color: #00b2b1;
}
