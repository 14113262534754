@charset "UTF-8";
.dark_blue {
  color: #25215f;
}

@font-face {
  font-family: Lexend;
  src: url(./Lexend-VariableFont_wght.ttf);
}

.grey {
  color: #5a5a5a;
}

.red {
  color: #fe000c;
}

.ubbe-blue {
  color: #25215f;
}

.ubbe-yellow {
  color: #ffcf01;
}

.ubbe-yellow-light {
  color: #fffef8;
}

.ubbe-yellow-bg {
  background-color: #ffcf01 !important;
}

.ubbe-blue-bg {
  background-color: #25215f !important;
  font-size: 18px !important;
}

.ubbe-red-bg {
  background-color: #fe000c !important;
}

.ubbe-logo-size {
  height: 3em;
}

.nav-background {
  background-color: white !important;
}

.nav-item-font-style {
  color: var(--title-color) !important;
}

.nav-link-style {
  font-weight: 600;
}

.nav-left-left {
  background-color: white;
  border-style: solid;
  border-width: 1px 0 1px 1px;
  border-color: #ffe573;
}

.nav-left-middle {
  background-color: white;
  border-style: solid;
  border-width: 1px 0 1px 0;
  border-color: #ffe573;
}

.nav-left-right {
  background-color: white;
  border-style: solid;
  border-width: 1px 1px 1px 0;
  border-radius: 0 4px 4px 0;
  border-color: #ffe573;
}

.nav-left-full {
  background-color: white;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  border-color: #ffe573;
}

@media only screen and (min-width: 600px) and (max-width: 767px) {
  .nav-dots {
    padding-top: 1em;
    display: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .nav-dots {
    padding-top: 1em;
    margin: 0;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1279px) {
  .nav-dots {
    padding-top: 1em;
    margin: 0 0.3em;
  }
  .nav-item-style {
    margin-top: 1em;
  }
}
@media only screen and (min-width: 1280px) {
  .nav-dots {
    padding-top: 1em;
    margin: 0 0.5em;
  }
  .nav-item-style {
    margin-top: 1em;
  }
}
.content-spacing {
  margin: 7% 8%0 !important;
}
.error_text {
  width: 100%;
  margin-top: 0.25rem;
  font-family: Lexend;
  font-size: 12px;
  font-weight: 300;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
  color: #fc0000;
}

.ubbe-card-white {
  background-color: #fff;
  border-radius: 0.66em;
  -webkit-box-shadow: 0px 0px 5px 4px rgba(235, 235, 235, 0.91);
  -moz-box-shadow: 0px 0px 5px 4px rgba(235, 235, 235, 0.91);
  box-shadow: 0px 0px 5px 4px rgba(235, 235, 235, 0.91);
}

.landing-br {
  border-right: 2px solid rgba(235, 235, 235, 0.91);
}

.num-hex {
  height: 2.2rem !important;
}

html {
  scroll-behavior: smooth;
}

body,
html,
* {
  --bg-color: white;
  --text-color: black;
  --title-color: #25215f;
  font-family: Lexend, Arial, sans-serif !important;
}

.normal_bg {
  background: url("../icons/Vector_large.png"), url("../icons/Vector.png");
  background-position: 50% 13vh, 35% -220%;
  background-repeat: no-repeat, no-repeat;
  scroll-behavior: smooth !important;
  overflow-x: hidden !important;
}

.app-bg {
  background-color: #f8f8f8;
  scroll-behavior: smooth !important;
  overflow-x: hidden !important;
}

.landing {
  background: url("../icons/Banner1.png"), url("../icons/Polygon.png"), url("../icons/Polygon.png"), url("../icons/Vector (2).png"), url("../icons/Vector (1).png"),
    url("../icons/Vector (2).png"), url("../icons/Vector (1).png");
  background-position: top left, center 32vh, 52vw -40vh, 75% 135vh, 20% 170vh, 20% 205vh, 75% 240vh;
  background-repeat: no-repeat;
  background-size: 100% 50vh, 120% 80vh, 70% 60vh, 65% auto, 35% auto, 65% auto, 35% auto;
  overflow-x: hidden;
}

/***********************
       Font
************************/
html,
body,
label,
p {
  font-family: Lexend, Arial, sans-serif;
}
.form-control {
  font-family: Lexend !important;
}
label {
  font-size: 1.125rem;
}

.font-body-text {
  font-size: 11pt;
}

.link-no-style {
  // text-decoration: none;
  color: unset;
}

.btn-link-style {
  text-decoration: underline;
  background: none;
  border: none;
  padding: 0;
  font-weight: 300;
  color: unset;
  cursor: pointer;
}

/***********************
        Card Style
************************/
.card_yellow {
  background-color: #ffe573 !important;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3.9px;
  width: 100%;
  border: unset;
}

.ubbe-card {
  border-radius: 8px;
  background: var(--white, #fff);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
}

.ubbe-card:hover,
.card_yellow:hover {
  // box-shadow: 0 8px 8px rgba(0, 0, 0, 0.25);
}

.ubbe-card-selected {
  background-color: #fffef8 !important;
  border: 2px solid #25215f !important;
  border-radius: 0.25rem;
}

.ubbe-list-group {
  border-right: unset !important;
  border-left: unset !important;
  background-color: unset !important;
  border-radius: unset !important;
  border-top: unset !important;
}

.list-group-item:last-child {
  border-bottom: unset !important;
}

.ubbe-card-login-size {
  margin: 15%;
}

.card-blue {
  background-color: #25215f !important;
  border-radius: 3.9px;
  width: 100%;
  border: unset;
  color: white;
}

/***********************
        Modals
************************/
.modal-style {
  background-color: white;
  padding: 2rem;
  border-radius: 1rem;
  min-width: 25rem;
}

/***********************
        List Group
************************/
.list-group-item.active {
  background-color: #25215f;
  border-color: #25215f;
}
.list-group-item-dashboard-active {
  background-color: #25215f !important;
  border-color: #25215f !important;
}

/***********************
        Inputs
************************/
.ubbe-input,
textarea,
.ubbe-textarea {
  background-color: #fff !important;
  border-radius: 6px !important;
  border: 1px solid #e2e8f0;
  height: 48px;
  padding: 12px;
  font-family: Lexend;
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

.ubbe-input::placeholder,
.ubbe-textarea::placeholder,
textarea::placeholder {
  color: rgba(0, 0, 0, 0.5);
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 100%;
}

.nav-search::placeholder {
  font-family: Lexend;
  font-size: 14px;
  font-weight: 300;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  padding-right: 40px;
}

.no-border-input {
  border: unset !important;
  background-color: unset !important;
}

.dropdown-style {
  background-color: #fffef8 !important;
  border: 2px solid #25215f !important;
  border-radius: 0.25rem;
  font-family: Lexend;
}

/***********************
        Buttons
************************/
.btn-ubbe-new-blue-active {
  border-radius: 6px !important;
  background: #251163 !important;
  padding: 16px 16px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  color: var(--White, var(--white, #fff)) !important;
  border: none;
  /* table heading */
  font-family: Lexend !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 100% !important; /* 18px */
  gap: 8px;
  height: 48px !important;
}
.btn-ubbe-new-blue {
  border-radius: 6px !important;
  background: #251163 !important;
  padding: 16px 32px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  color: var(--White, var(--white, #fff)) !important;
  border: none;
  /* table heading */
  font-family: Lexend !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 100% !important; /* 18px */
  gap: 8px;
  height: 48px !important;
}
.btn-ubbe-primary {
  border-radius: 6px !important;
  background: #251163 !important;
  padding: 16px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  color: var(--White, var(--white, #fff)) !important;
  border: none;
  /* table heading */
  font-family: Lexend !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 100% !important; /* 18px */
  gap: 8px;
  height: 48px !important;
}
.btn-ubbe-new-blue:disabled {
  background-color: #e5e5e5 !important;
  color: #747474 !important;
  border: none !important;
}
.btn-ubbe-nocolor {
  border-radius: 6px !important;
  padding: 16px 32px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  color: var(--White, var(--white, #fff)) !important;

  /* table heading */
  font-family: Lexend !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 100% !important; /* 18px */
  gap: 8px;
  height: 48px !important;
}
.btn-ubbe-secondary {
  border-radius: 6px !important;
  border: 1px solid #251163 !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 48px !important;
  color: #251163 !important;
  background-color: white;
  font-family: Lexend !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 400 !important;
}
.btn-ubbe-ghost {
  border: none !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 48px !important;
  text-decoration: underline !important;
  color: #251163 !important;
  cursor: pointer;
  /* table heading */
  font-family: Lexend !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 100%; /* 18px */
}
.btn-ubbe-new-back {
  border-radius: 6px !important;
  background: #f3f0f0 !important;
  display: flex;
  padding: 16px 32px !important;
  justify-content: center;
  align-items: center;

  /* table heading */
  color: var(--Grey-Black, #000) !important;

  /* table heading */
  font-family: Lexend;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 18px */
  height: 48px !important;
  max-width: 131px;
}
.btn-ubbe-white {
  border-radius: 6px !important;
  background: white !important;
  display: flex !important;
  padding: 16px !important;
  justify-content: center !important;
  align-items: center !important;

  /* table heading */
  color: var(--Grey-Black, #251163) !important;

  /* table heading */
  font-family: Lexend;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 18px */
  height: 42px !important;
}

.btn-ubbe-danger {
  border-radius: 6px !important;
  background: #fc0000 !important;
  padding: 16px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  color: var(--White, var(--white, #fff)) !important;
  border: none;
  /* table heading */
  font-family: Lexend !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 100% !important; /* 18px */
  gap: 8px;
  height: 48px !important;
}

.btn-ubbe {
  color: #333333 !important;
  background-color: #ffcf01 !important;
  border-color: #ffcf01 !important;
}

.btn-ubbe-blue {
  color: white !important;
  background-color: #25215f !important;
  border-color: #25215f;
}

.btn-account-creation {
  color: white !important;
  background-color: #25215f !important;
  border-color: #25215f;
}

.btn-plain {
  color: unset;
  background-color: unset;
  border: unset;
}

.btn-track {
  padding: 16px 56px !important;
}

.btn-ubbe:hover,
.btn-ubbe-blue:hover,
.btn-plain:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2) !important;
}

.btn-ubbe-blue:hover {
  color: white;
  background-color: #5a5a5a !important;
  border-color: #5a5a5a !important;
}

.btn-plain:hover {
  color: #333333;
  background-color: #ffcf01;
  border-color: #ffcf01;
}

.btn-ubbe-muted {
  color: #000000 !important;
  background-color: #f3f0f0 !important;
  border-color: #f3f0f0 !important;

  border-radius: 6px !important;
  padding: 16px 32px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border: none;
  /* table heading */
  font-family: Lexend !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 100% !important; /* 18px */
  gap: 8px;
  height: 48px !important;
}

.btn-ubbe-gray {
  color: #000000 !important;
  background-color: #f3f0f0 !important;
  border-color: #f3f0f0 !important;

  border-radius: 50px !important;
  padding: 16px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border: none;
  font-family: Lexend !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 100% !important;
  gap: 8px;
  height: 48px !important;
}

.btn-ubbe-new-blue:hover,
.ubbe-blue-bg:hover,
.btn-ubbe-green:hover,
.btn-ubbe-muted:hover,
.btn-ubbe-gray:hover,
.btn-ubbe-primary:hover,
.btn-ubbe-danger:hover,
.btn-group-item:hover,
.btn-account-creation:hover,
.btn-ubbe-ghost:hover {
  opacity: 0.7; /* You can adjust the opacity value as per your preference */
}

.btn-ubbe-secondary:hover {
  color: white !important;
  background-color: #251163;
}

.request-quote-icon-path {
  transition: fill 0.3s ease;
}

.btn-ubbe-secondary:hover .request-quote-icon-path {
  fill: white;
}
.btn-ubbe-no-style {
  background-color: unset;
  border: unset;
}

/***********************
        Icons
************************/
.ubbe-icon {
  font-size: 1.2rem;
  color: #333333;
}

.ubbe-icon-table {
  font-size: 0.85rem;
  color: #333333;
}

/***********************
        Tracking BBE
************************/
.bee_height {
  height: 5rem !important;
}

/***********************
        RESPONSIVE
************************/
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1280px) {
  .landing {
    background: url("../icons/Banner1.png"), url("../icons/Polygon.png"), url("../icons/Polygon.png"), url("../icons/Vector (2).png"), url("../icons/Vector (1).png");
    background-position: top left, center 22vh, 45vw -65vh, 50% 136vh, -30% 203vh;
    background-repeat: no-repeat, no-repeat;
    background-size: 100% 54vh, 100% 105vh, 70% 80vh, 85% auto, 55% auto;
    overflow-x: hidden;
  }
  .normal_bg {
    background: url("../icons/Vector_large.png"), url("../icons/Vector.png"), url("../icons/Vector.svg"), url("../icons/Vector_X_Large.png"), url("../icons/Vector_large.png"),
      url("../icons/Vector.png");
    background-position: 50% 13vh, 23% 85vh, 75% 153vh, -2% 203vh, 50% 333vh, 23% 408vh;
    background-repeat: no-repeat;
    overflow-x: hidden !important;
    -ms-overflow-x: hidden !important;
  }
}

/***********************
        RESPONSIVE
************************/
.request-demo-bg {
  background: #fffef8;
  box-shadow: 0 -6px 34px rgba(0, 0, 0, 0.05);
  border: none !important;
}

.bbe-logo-sm {
  width: auto !important;
  height: 10em !important;
}

.padding-spacing {
  padding-left: 6% !important;
  padding-right: 6% !important;
}

.tracking-spacing {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

/***********************
        Accordion
************************/
.accordion-item {
  background-color: unset;
  border: unset;
}

/***********************
        Footer
************************/
.footer-background {
  background: #25215f;
}

.social-icon {
  height: 25px !important;
  width: 25px !important;
}

.footer-font-header {
  font-size: 1.1em !important;
  color: white !important;
  font-weight: bold !important;
}

.footer-font {
  font-size: 0.8em;
  color: white;
  margin-bottom: 0.5rem;
}

.modal-style {
  background-color: white;
  padding: 2rem;
  border-radius: 1rem;
  min-width: 25rem;
}

.modal-header {
  border-bottom: unset;
}

.modal-footer {
  border-top: unset;
}

.foot-container-bg-color {
  background-color: #1e1a4c;
}

/*****************
Solutions
******************/
.solutions-card {
  text-align: center;
  padding: 1em;
  border: 3px solid #e1e1e1;
  margin-bottom: 3em;
  -webkit-transition: transform 0.5s, border 0.4s;
  transition: transform 0.5s, border 0.4s;
  background: #fffef8;
  /* Bucket Drop-Shadow */
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.solution-options {
  width: 70%;
}

@media only screen and (max-width: 1280px) {
  .main-content-solutions {
    width: 60%;
    margin: 8% 20% 0% 20%;
    /* Turn on the grid */
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    /* Apply a 20px gutter to rows and columns */
    grid-gap: 50px 100px;
  }
}
@media screen and (min-width: 1281px) {
  .main-content-solutions {
    width: 70%;
    margin: 10% 15% 0 15%;
    /* Turn on the grid */
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    /* Apply a 20px gutter to rows and columns */
    grid-gap: 50px 100px;
  }
}
/***********************
        Feature Page
************************/
.feature-img {
  width: 70%;
}

/***********************
        FAQ Page
************************/
.faq-icon-size {
  height: 5rem;
  width: 5rem;
}

.q-pipe {
  border-right: 1px solid black;
  height: 2rem;
  display: inline-block;
}

.small-screen-faq-help-text {
  font-family: Verdana, Arial, sans-serif;
}

.a-pipe {
  border-right: 1px solid black;
  height: 3rem;
  display: inline-block;
}

/***********************
        Team Page
************************/
.cardBtn {
  background-color: #ffcf01 !important;
  border: none !important;
  text-align: center !important;
}

.paragraph-link {
  text-decoration: none;
}

/***********************
        Error Page
************************/
.errorDiv {
  background: #25215f;
  height: 90vh;
}

.error-title {
  padding-top: 8%;
}

.error {
  font-weight: 700;
  text-align: center;
  font-size: 2.6em;
}

.error-num {
  font-size: 9.5em;
}

.error-message {
  font-size: 1.8em;
}

.error-arrow {
  text-align: center !important;
  position: relative;
  left: 48%;
  margin-top: 1%;
  margin-bottom: 1%;
}

@media only screen and (max-width: 1280px) {
  .error {
    font-size: 2em;
  }
  .error-num {
    font-size: 6.8em;
  }
  .error-title {
    padding-top: 5%;
  }
  .error-message {
    font-size: 1.5em;
  }
  .error-arrow {
    text-align: center !important;
    position: relative;
    left: 48%;
    margin-top: 0.2%;
    margin-bottom: 0.2%;
  }
}
/***********************
       Rate Table Style
************************/
.ubbe-row {
  border: 1px solid #e6e6e6 !important;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.ubbe-row:hover {
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.25);
}

.ubbe-row-spacing-header {
  border-collapse: separate;
  border-spacing: 0 0;
}

.ubbe-row-spacing {
  border-collapse: separate !important;
  border-spacing: 0 1.25rem !important;
}

/***********************
       B ToolTip Style
************************/
.tooltip-inner {
  text-align: left;
  --bs-tooltip-bg: var(--bs-secondary);
}

tbody tr.selected {
  background-color: rgba(37, 33, 95, 0.85);
  color: white !important;
}

tbody tr.selected:hover {
  background-color: rgba(37, 33, 95, 0.5);
}

/***********************
       OP - Options
************************/

/* CustomStyles.css */

.headingGroundCareer {
  color: var(--Grey-Black, #000);

  /* heading */
  font-family: Lexend;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 20px;
}
/* Style the checkbox container */
.option-radio-success {
  position: relative;
  padding-left: 28px; /* Set the padding to create space for a custom checkbox */
}

/* Style the custom checkbox */
.option-radio-success input[type="checkbox"] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0; /* Hide the default checkbox */
}

/* Style the custom checkbox appearance */
.option-radio-success input[type="checkbox"] + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 1.25px;
  width: 20px; /* Set the width of the custom checkbox */
  height: 20px; /* Set the height of the custom checkbox */
  border: 2px solid #251163; /* Set the border color of the custom checkbox */
  background-color: #fff; /* Set the default background color of the custom checkbox */
  border-radius: 6px;
}

/* Style the tick mark */
.option-radio-success input[type="checkbox"]:checked + label:after {
  content: url("../icons/tick-square.svg");
  position: absolute;
  left: 9px;
  top: 10.25px;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
}

/* Style the label */
.global-text {
  font-weight: normal; /* Set the default font weight for unselected labels */
  color: var(--Grey-Black, #000);
  font-family: Lexend;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.global-text-16 {
  font-weight: normal; /* Set the default font weight for unselected labels */
  color: var(--Grey-Black, #000);
  font-family: Lexend;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  font-size: 16px;
}
.option-radio-success label {
  font-weight: normal; /* Set the default font weight for unselected labels */
  color: var(--Grey-Black, #000);
  font-family: Lexend;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 100%; /* 18px */
  min-width: 115px;
  cursor: pointer;
}

/* Style the label when the checkbox is checked */
.option-radio-success input[type="checkbox"]:checked + label {
  color: var(--Grey-Black, #000);
  font-family: Lexend;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 18px */
}

.option-radio-click input[type="radio"]:checked + label {
  font-weight: 400;
}
.option-radio-click label {
  font-weight: 300;
}
.option-radio-success.selected {
  background-color: unset !important;
}

// OLD ground career option

// .option-radio div {
//   clear: both;
//   overflow: hidden;
// }

// .option-radio label {
//   width: 100%;
//   border-radius: 3px;
//   border: 1px solid #D1D3D4;
//   font-weight: normal;
// }

// .option-radio input[type=radio]:empty,
// .option-radio input[type=checkbox]:empty {
//   display: none;
// }

// .option-radio input[type=radio]:empty ~ label,
// .option-radio input[type=checkbox]:empty ~ label {
//   position: relative;
//   line-height: 2.5em;
//   text-indent: 3.25em;
//   margin-top: 2em;
//   cursor: pointer;
//   -webkit-user-select: none;
//   -moz-user-select: none;
//   -ms-user-select: none;
//   user-select: none;
// }

// .option-radio input[type=radio]:empty ~ label:before,
// .option-radio input[type=checkbox]:empty ~ label:before {
//   position: absolute;
//   display: block;
//   top: 0;
//   bottom: 0;
//   left: 0;
//   content: "";
//   width: 2.5em;
//   background: #D1D3D4;
//   border-radius: 3px 0 0 3px;
// }

// .option-radio input[type=radio]:hover:not(:checked) ~ label,
// .option-radio input[type=checkbox]:hover:not(:checked) ~ label {
//   color: #888;
// }

// .option-radio input[type=radio]:hover:not(:checked) ~ label:before,
// .option-radio input[type=checkbox]:hover:not(:checked) ~ label:before {
//   content: "✔";
//   text-indent: 0.9em;
//   color: #C2C2C2;
// }

// .option-radio input[type=radio]:checked ~ label,
// .option-radio input[type=checkbox]:checked ~ label {
//   color: #777;
// }

// .option-radio input[type=radio]:checked ~ label:before,
// .option-radio input[type=checkbox]:checked ~ label:before {
//   content: "✔";
//   text-indent: 0.9em;
//   color: #333;
//   background-color: #ccc;
// }

// .option-radio input[type=radio]:focus ~ label:before,
// .option-radio input[type=checkbox]:focus ~ label:before {
//   box-shadow: 0 0 0 3px #999;
// }

// .option-radio-success input[type=radio]:checked ~ label:before,
// .option-radio-success input[type=checkbox]:checked ~ label:before {
//   color: #fff;
//   background-color: #25215F;
// }

/***********************
       Dropdowns
************************/
.choices {
  margin-bottom: unset !important;
}

.choices__inner {
  background-color: #fffef8 !important;
  border: 2px solid #25215f !important;
  min-height: unset !important;
  max-height: 2.6rem !important;
  font-size: 100% !important;
  padding: 0.4688rem !important;
  border-radius: 5px !important;
}

.choices__list--single {
  display: inline-block;
  padding: unset !important;
  width: 100%;
}

/***********************
      Loader
************************/
.spinner {
  width: 164px;
  height: 164px;
  border: none !important;
  // border-color: #ffcf01 transparent #ffcf01 transparent;
  // border-radius: 50%;
  // animation: spin-anim 1.2s linear infinite;
  // position: fixed;
  // left: 50%;
  // top: 50%;
  z-index: 9999;
  background-image: url("../icons/new_icon/BBEprocessing1.gif") !important;
}

.loader-container {
  left: 44%;
  top: 37%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: transparent;
  z-index: 999;
}

@keyframes spin-anim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.outline-search-input:focus {
  outline: none;
}

.ubbe-toast {
  background-color: rgba(255, 255, 255, 0.95);
}

/*# sourceMappingURL=ubbe.css.map */
.attention-screen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Adjust the opacity as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Adjust z-index to ensure it's on top */
}

.cursor_pointer {
  cursor: pointer;
}
.mb_0 {
  margin-bottom: 0px !important;
}
.hovered-color {
  transition: color 0.3s ease;
}

.hovered-color:hover {
  color: #0d6efd;
}

// Date Picker Start //
.react-datepicker__navigation {
  top: 26px !important;
}
.react-datepicker-wrapper {
  width: 100% !important;
}
.react-datepicker__header {
  background-color: white !important;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: var(--Black, #1a141f) !important;
  text-align: center !important;

  /* heading */
  font-family: Lexend !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal;
  margin-top: 6px !important;
  margin-bottom: 11px !important;
}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
  display: none !important;
}
.react-datepicker__day-name {
  color: var(--Border, #aba7af) !important;
  text-align: center;

  /* Description */
  font-family: Lexend;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.react-datepicker__day,
.react-datepicker__time-name {
  color: var(--Black, #1a141f) !important;
  text-align: center !important;

  /* Body txt/body-XS */
  font-family: Lexend !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 140%; /* 19.6px */
}
.calendarIcon {
  right: 5px;
  top: 7px;
}
.react-datepicker {
  padding: 10px 25px 25px 25px;
}
.react-datepicker__day--selected {
  border-radius: 3px !important;
  background: var(--Main-color, #251163) !important;
  color: var(--white, #fff) !important;
  text-align: center;

  /* Body txt/body-XS */
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
}
.react-datepicker__triangle::after {
  display: none !important;
}
.react-datepicker__triangle::before {
  display: none !important;
}
// Date Picker End //
.d-flex {
  display: flex;
}
.justify_center_align_end {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.justify_end_align_center {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.justify_end_align_start {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}
.nav-tabs .nav-link.active {
  color: #ffff !important;
  background-color: #25215f !important;
  border-color: #25215f !important;
  font-weight: 700 !important;
}
.selected-rates-background {
  // background-color: rgba(37, 33, 95, 0.85);
  border-bottom: 1px solid var(--Stroke, #000);
  background: var(--Supporting-Color-1, #251163);
  color: #fff !important;
}
.table tr.selected-rates-background > td {
  color: #fff !important;
}

.main-page {
  margin-left: 6.8rem;
}
.width-input {
  min-width: 331px !important;
}
.d-flexformobile {
  display: flex;
}

@media only screen and (max-width: 1080px) {
  .d-flexformobile {
    display: block;
  }
}
